import { Button as MantineButton } from '@mantine/core';
import clsx from 'clsx';
import type { MouseEventHandler, ReactNode } from 'react';
import { forwardRef } from 'react';

type ColorType = 'primary' | 'secondary' | 'negative' | 'border' | 'glyphs_normal';
type ButtonProps = {
  variant: 'filled' | 'outline' | 'transparent' | 'default';
  color?: ColorType;
  size?: 'large' | 'small' | 'free';
  type?: 'submit' | 'button';
  value?: string;
  name?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  disabled?: boolean;
} & React.ComponentPropsWithoutRef<typeof MantineButton>;

const Colors: { [key in ColorType]: string } = {
  primary: '#5A64D7',
  secondary: '#DEDFFF',
  negative: '#CB1515',
  border: '#CFCFE5',
  glyphs_normal: '#333333',
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      color = 'primary',
      children,
      size = 'small',
      type,
      value,
      name,
      className: overrideClassName,
      ...props
    },
    ref
  ) => {
    const className = clsx(
      'rounded-sm text-center text-base font-semibold tracking-wider',
      {
        'p-0': variant === 'transparent' || size === 'free',
        'px-4 py-2': variant !== 'transparent' && size !== 'free',
      },
      { 'h-[32px]': size === 'small', 'h-[48px]': size === 'large' },
      overrideClassName
    );
    return (
      <MantineButton
        ref={ref}
        className={className}
        color={Colors[color]}
        name={name}
        type={type}
        value={value}
        variant={variant}
        {...props}
      >
        {children}
      </MantineButton>
    );
  }
);

type SquareButtonProps = {
  variant: 'filled' | 'outline' | 'transparent';
  color?: ColorType;
  size?: number;
  type?: 'submit' | 'button';
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
} & React.ComponentPropsWithoutRef<typeof MantineButton>;

export const SquareButton = forwardRef<HTMLButtonElement, SquareButtonProps>(
  (
    { variant, color = 'primary', children, size, type, className: overrideClassName, ...props },
    ref
  ) => {
    const className = clsx(
      'rounded-sm text-center text-base font-semibold tracking-wider p-0 border-none',
      overrideClassName
    );
    return (
      <MantineButton
        ref={ref}
        className={className}
        color={Colors[color]}
        style={{
          width: size,
          height: size,
        }}
        type={type}
        variant={variant}
        {...props}
      >
        {children}
      </MantineButton>
    );
  }
);
